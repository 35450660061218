<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>

		<!-- Authors Table -->
		<a-row :gutter="24" type="flex">  
		</a-row>
		<!-- / Authors Table -->

		<!-- Projects Table -->
		<a-row :gutter="24" type="flex">

			<!-- Projects Table Column -->
			<a-col :span="24" class="mb-24">

				<!-- Projects Table Column -->
				<ProjectListTable 
					:data="this.ProjectsData"
				></ProjectListTable>
				<!-- / Projects Table Column -->

			</a-col>
			<!-- / Projects Table Column -->

		</a-row>
		<!-- / Projects Table -->

	</div>
</template>

<script>
	import Vue from 'vue'

	// "Projects" table component.
	import ProjectListTable from '../../components/Cards/Projects/user_project_list' ;
	import { userService } from '../../services/user.service';

	// "Authors" table list of columns and their properties. 
	// "Projects" table list of rows and their properties.
	const ProjectsData = [ 
	];

	export default ({
		components: {
			ProjectListTable,
		},
        created(){
			this.user = Vue.prototype.$me;
			// load user list
			userService.getProjects().then(response => {    
				if (response.status == 200) {
				return response.json();            
				} else {
				alert('asd');
				}
			}).then((response) => {

				this.ProjectsData = response.data.projects
				var that = this;
			
				this.ProjectsData.forEach(function(user,key){ 
				
				});
				/*
				
				response.ProjectsData.groups.forEach(function(user,key){ 
				that.userGroups[key] = user.name;
				});
				*/
			}).catch((e) => {
				console.log(e);
			});
        },
		data() {
			return {
                user: null,
				ProjectsData,
			}
		},
	})

</script>

<style lang="scss">
</style>